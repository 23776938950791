<template>
    <b-modal id="form-akun" no-close-on-backdrop no-close-on-esc>
        <h2 class="text-danger text-center mb-2">
            <strong>Rp {{ formatRupiah(totalPerbaikan) }}</strong>
        </h2>
        <b-form-group label="Pilih Akun" class="mb-2">
            <v-select :options="akuns" v-model="form.akun" label="text">
            </v-select>
        </b-form-group>
        <b-form-group label="Pilih Kas" class="mb-2">
            <v-select :options="listKas" v-model="form.kas" label="text">
            </v-select>
            <i class="text-danger" v-if="invalidKas">
                Saldo Kas kurang
            </i>
        </b-form-group>
        <template #modal-footer>
            <b-button variant="primary" :disabled="invalidKas" @click.prevent="submit">Simpan</b-button>
        </template>
    </b-modal>
</template>
<script>
import {BModal, BButton, BFormGroup} from 'bootstrap-vue'
import vSelect from 'vue-select'
export default {
    data: () => ({
        form: {
            akun: null,
            kas: null
        }
    }),
    methods: {
        submit() {
            if(this.invalidKas) {
                this.displayError({
                    message: 'Saldo kas yang dipilih kurang!'
                })
                return false
            }

            const payload = {
                id_akun: this.form.akun.value,
                id_kas: this.form.kas.value,
                id: this.$route.params.id,
                aktor: 'finance',
                status: 1
            }

            this.$emit('onSubmit', payload)
        }
    },
    components: {
        BModal, BButton, BFormGroup, vSelect
    },
    computed: {
        akuns() {
            return this.$store.state.asset.akunOptions
        },
        listKas() {
            return this.$store.state.asset.kasOptions
        },
        totalPerbaikan() {
            return this.$store.state.asset.currentTotalPerbaikan
        },
        invalidKas() {
            if(!this.form.kas) return false
            const kas = this.listKas.find(item => item.value == this.form.kas.value)
            if(!kas) return false

            return kas && (parseInt(kas.saldo) < 1 || parseInt(kas.saldo) < this.unFormatRupiah(this.totalPerbaikan))
        },
    }
}
</script>