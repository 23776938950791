<template>
    <section>
        <b-modal no-close-on-backdrop no-close-on-esc size="xl" id="modal-asset" title="Daftar Aset">
            <b-table
                responsive
                bordered
                :fields="fields"
                :items="localAssets"
                class="mb-2"
            >
                <template #head(checkbox)>
                    <b-form-checkbox v-model="allChecked" />
                </template>
                <template #cell(checkbox)="{item}">
                    <b-form-checkbox v-if="parseInt(item.stok) > 0" v-model="selectedAssets" :value="item" />
                    <feather-icon icon="XIcon" class="text-danger" v-else></feather-icon>
                </template>
                <template #cell(nama)="{item}">
                    {{ item.asset.nama }}
                </template>
                <template #cell(kode)="{item}">
                    {{ item.asset.kode }}
                </template>
                <template #cell(satuan)="{item}">
                    {{ item.asset.satuan ? item.asset.satuan.satuan : '' }}
                </template>
                <template #cell(harga_dasar)="{item}">
                    Rp {{ formatRupiah(item.asset.harga_dasar) }}
                </template>
                <template #cell(jumlah)="{item}">
                    <b-form-input v-if="parseInt(item.stok) > 0" v-model="item.jumlah"></b-form-input>
                    <i class="text-danger" v-else><small>Stok Aset 0</small></i>
                </template>
            </b-table>
            <template #modal-footer>
                <b-button :disabled="!thereSelectedAsset" @click.prevent="submit" variant="primary">Pilih Aset</b-button>
            </template>
        </b-modal>
    </section>
</template>
<script>
import {BTable, BButton, BFormCheckbox, BFormInput} from 'bootstrap-vue'
export default {
    components: {
        BTable, BButton, BFormCheckbox, BFormInput
    },
    computed: {
        thereSelectedAsset() {
            return this.selectedAssets.length > 0
        }
    },
    watch: {
        allChecked(val) {
            if(val) {
                this.selectedAssets = this.localAssets.filter(item => parseInt(item.stok) > 0)
            }
            else {
                this.selectedAssets = []
            }
        },
        "$store.state.asset.stocks"(val) {
            if(val) {
                const assets = JSON.parse( JSON.stringify(val) )
                this.localAssets = assets.filter(aset => aset.asset != null)
                this.localAssets.map(asset => {
                    asset.jumlah = 0
                })
            }
        }
    },
    data: () => ({
        localAssets: [],
        selectedAssets: [],
        allChecked: false,
        fields: [
            {key: 'checkbox', label: '#'},
            {key: 'nama', label: 'Nama Aset'},
            {key: 'kode', label: 'Kode'},
            {key: 'harga_dasar', label: 'Harga Dasar'},
            {key: 'stok', label: 'Stok'},
            {key: 'jumlah', label: 'Jumlah'},
            {key: 'satuan', label: 'Satuan'},
        ]
    }),
    methods: {
        updateLocalAset(aset) {
            this.localAssets.push(aset)
            this.$bvModal.hide('modal-new-asset')
        },
        addNewAsset() {
            this.$bvModal.show('modal-new-asset')
        },
        submit() {
            if(this.selectedAssets.length < 1) {
                this.displayError({
                    message: 'Harap pilih minimal 1 asset!'
                })
                return false
            }

            // validation item 0
            const there0Jumlah = this.selectedAssets.some(item => item.jumlah < 1)
            if(there0Jumlah) {
                this.displayError({
                    message: 'Harap isi jumlah aset minimal 1!'
                })
                return false
            }

            // validation overstock
            const thereOverStock = this.selectedAssets.some(item => parseInt(item.jumlah) > parseInt(item.stok) )
            if(thereOverStock) {
                this.displayError({
                    message: 'Terdapat aset yang melebihi jumlah stok!'
                })
                return false
            }
            let result = Object.assign([], this.selectedAssets)
            
            const payloads = []
            result.map(stock => {
                payloads.push({
                    id_perbaikan_asset: this.$route.params.id,
                    penyimpanan_id: stock.id,
                    jumlah: parseInt(stock.jumlah),
                    keterangan: null,
                    biaya: parseInt(stock.asset.harga_dasar) * parseInt(stock.jumlah),
                    status: 0,
                    penyimpanan: stock
                })
            })
            this.$emit('submit', payloads)
        }
    }
}
</script>