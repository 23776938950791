var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-modal', {
    attrs: {
      "id": "form-akun",
      "no-close-on-backdrop": "",
      "no-close-on-esc": ""
    },
    scopedSlots: _vm._u([{
      key: "modal-footer",
      fn: function () {
        return [_c('b-button', {
          attrs: {
            "variant": "primary",
            "disabled": _vm.invalidKas
          },
          on: {
            "click": function ($event) {
              $event.preventDefault();
              return _vm.submit($event);
            }
          }
        }, [_vm._v("Simpan")])];
      },
      proxy: true
    }])
  }, [_c('h2', {
    staticClass: "text-danger text-center mb-2"
  }, [_c('strong', [_vm._v("Rp " + _vm._s(_vm.formatRupiah(_vm.totalPerbaikan)))])]), _c('b-form-group', {
    staticClass: "mb-2",
    attrs: {
      "label": "Pilih Akun"
    }
  }, [_c('v-select', {
    attrs: {
      "options": _vm.akuns,
      "label": "text"
    },
    model: {
      value: _vm.form.akun,
      callback: function ($$v) {
        _vm.$set(_vm.form, "akun", $$v);
      },
      expression: "form.akun"
    }
  })], 1), _c('b-form-group', {
    staticClass: "mb-2",
    attrs: {
      "label": "Pilih Kas"
    }
  }, [_c('v-select', {
    attrs: {
      "options": _vm.listKas,
      "label": "text"
    },
    model: {
      value: _vm.form.kas,
      callback: function ($$v) {
        _vm.$set(_vm.form, "kas", $$v);
      },
      expression: "form.kas"
    }
  }), _vm.invalidKas ? _c('i', {
    staticClass: "text-danger"
  }, [_vm._v(" Saldo Kas kurang ")]) : _vm._e()], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }